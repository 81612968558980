import React from 'react';

export default function Datenschutz() {
  return (
    <>
      <h4>Datenschutzerkl&auml;rung</h4>
      <p>
        Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:
      </p>
      <p>
        SAGOS
        <br />
        Adrian Bühler
        <br />
        Spiezbergstrasse 18 <br />
        3700 Spiez
      </p>
      <p>
        Telefon: 079 390 89 56
        <br />
        E-Mail: kontakt@sagos.info
        <br />
        WebSite: https://sagos.info{' '}
      </p>
      <h2>Allgemeiner Hinweis</h2>{' '}
      <p>
        Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und
        den datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz,
        DSG) hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie
        auf Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber
        dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr
        ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerkl&auml;rung.
      </p>
      <p>
        In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns,
        die Datenbanken so gut wie m&ouml;glich vor fremden Zugriffen,
        Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.
      </p>
      <p>
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B.
        bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann.
        Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht m&ouml;glich.
      </p>
      <p>
        Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der
        Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss der
        nachfolgenden Beschreibung einverstanden. Diese Website kann
        grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden Daten
        wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei,
        Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert,
        ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden.
        Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse
        werden soweit m&ouml;glich auf freiwilliger Basis erhoben. Ohne Ihre
        Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
      </p>
      <p>
        Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
        die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
        werden.
      </p>
      <h2>Dienste von Dritten</h2>{' '}
      <p>
        Diese Website verwenden allenfalls Google Maps f&uuml;r das Einbetten
        von Karten, Google Invisible reCAPTCHA f&uuml;r den Schutz gegen Bots
        und Spam sowie YouTube f&uuml;r das Einbetten von Videos.
      </p>
      <p>
        Diese Dienste der amerikanischen Google LLC verwenden unter anderem
        Cookies und infolgedessen werden Daten an Google in den USA
        &uuml;bertragen, wobei wir davon ausgehen, dass in diesem Rahmen kein
        personenbezogenes Tracking allein durch die Nutzung unserer Website
        stattfindet.
      </p>
      <p>
        Google hat sich verpflichtet, einen angemessenen Datenschutz gem&auml;ss
        dem amerikanisch-europ&auml;ischen und dem amerikanisch-schweizerischen
        Privacy Shield zu gew&auml;hrleisten.
      </p>
      <p></p>
      <p>&nbsp;</p>
      <h2>Verwendung von Adobe Fonts</h2>
      <p>
        Wir setzen Adobe Fonts zur visuellen Gestaltung unserer Website ein.
        Adobe Fonts ist ein Dienst der Adobe Systems Incorporated, 345 Park
        Avenue, San Jose, CA 95110-2704, USA (Adobe), der uns den Zugriff auf
        eine Schriftartenbibliothek gew&auml;hrt. Zur Einbindung der von uns
        benutzten Schriftarten, muss Ihr Browser eine Verbindung zu einem Server
        von Adobe in den USA aufbauen und die f&uuml;r unsere Website
        ben&ouml;tigte Schriftart herunterladen. Adobe erh&auml;lt hierdurch die
        Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde.
        Weitere Informationen zu Adobe Fonts, finden Sie in den
        Datenschutzhinweisen von Adobe, die Sie hier abrufen k&ouml;nnen:{' '}
        <a
          href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Adobe Fonts
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>Nutzung von Fonts.com</h2>{' '}
      <p>
        Diese Website nutzt Fonts.com, ein von Monotype Imaging Holdings Inc.
        bereitgestellter Service zur Visualisierung von Schriftarten, mit dem
        diese Website entsprechende Inhalte auf ihren Seiten einbinden kann.
        Erhobene personenbezogene Daten: Nutzungsdaten und verschiedene
        Datenarten, wie in der Datenschutzerklärung des Dienstes beschrieben.
        Verarbeitungsort: Vereinigte Staaten von Amerika (USA);{' '}
        <a
          href="http://www.monotype.com/legal/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Datenschutzerklärung
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>Urheberrechte</h2>{' '}
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
        anderen Dateien auf der Website, geh&ouml;ren ausschliesslich dem
        Betreiber dieser Website oder den speziell genannten Rechteinhabern. Für
        die Reproduktion von sämtlichen Dateien, ist die schriftliche Zustimmung
        des Urheberrechtstr&auml;gers im Voraus einzuholen.
      </p>
      <p>
        Wer ohne Einwilligung des jeweiligen Rechteinhabers eine
        Urheberrechtsverletzung begeht, kann sich strafbar und allenfalls
        schadenersatzpflichtig machen.
      </p>
      <p>&nbsp;</p>
      <h2>Allgemeiner Haftungsausschluss</h2>{' '}
      <p>
        Alle Angaben unseres Internetangebotes wurden sorgf&auml;ltig
        gepr&uuml;ft. Wir bem&uuml;hen uns, unser Informationsangebot aktuell,
        inhaltlich richtig und vollst&auml;ndig anzubieten. Trotzdem kann das
        Auftreten von Fehlern nicht v&ouml;llig ausgeschlossen werden, womit wir
        keine Garantie f&uuml;r Vollst&auml;ndigkeit, Richtigkeit und
        Aktualit&auml;t von Informationen auch journalistisch-redaktioneller Art
        &uuml;bernehmen k&ouml;nnen. Haftungsanspr&uuml;che aus Sch&auml;den
        materieller oder ideeller Art, die durch die Nutzung der angebotenen
        Informationen verursacht wurden, sind ausgeschlossen, sofern kein
        nachweislich vors&auml;tzliches oder grob fahrl&auml;ssiges Verschulden
        vorliegt.
      </p>
      <p>
        Der Herausgeber kann nach eigenem Ermessen und ohne Ank&uuml;ndigung
        Texte ver&auml;ndern oder l&ouml;schen und ist nicht verpflichtet,
        Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang
        zu dieser Website geschieht auf eigene Gefahr des Besuchers. Der
        Herausgeber, seine Auftraggeber oder Partner sind nicht verantwortlich
        f&uuml;r Sch&auml;den, wie direkte, indirekte, zuf&auml;llige, vorab
        konkret zu bestimmende oder Folgesch&auml;den, die angeblich durch den
        Besuch dieser Website entstanden sind und &uuml;bernehmen hierf&uuml;r
        folglich keine Haftung.
      </p>
      <p>
        Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung und Haftung
        f&uuml;r die Inhalte und die Verf&uuml;gbarkeit von Website Dritter, die
        &uuml;ber externe Links dieser Website erreichbar sind. F&uuml;r den
        Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber
        verantwortlich. Der Herausgeber distanziert sich damit ausdr&uuml;cklich
        von allen Inhalten Dritter, die m&ouml;glicherweise straf- oder
        haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.
      </p>
      <h2>&Auml;nderungen</h2>
      <p>
        Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne
        Vorank&uuml;ndigung anpassen. Es gilt die jeweils aktuelle, auf unserer
        Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung Teil
        einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer
        Aktualisierung über die &Auml;nderung per E-Mail oder auf andere
        geeignete Weise informieren.
      </p>
      <h2>Fragen an den Datenschutzbeauftragten</h2>{' '}
      <p>
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
        E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz zu
        Beginn der Datenschutzerkl&auml;rung aufgef&uuml;hrten, verantwortlichen
        Person in unserer Organisation.
      </p>
      <p>
        Spiez, 15.10.2020
        <br />
        Quelle:{' '}
        <a
          href="https://www.swissanwalt.ch"
          target="_blank"
          rel="noopener noreferrer"
        >
          SwissAnwalt
        </a>
      </p>{' '}
    </>
  );
}
