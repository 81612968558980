import React, { useState, useRef } from "react";
import "../addUsers/addStyle.scss";
import { Dropdown, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function Add({ members, firestore, storage }) {
  const [selectedUser, setSelectedUser] = useState(0);
  const [notification, setNotification] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [previewImage, setPreviewImage] = useState({ preview: "", data: {} });
  let formRef = useRef();
  const [person, setPerson] = useState({
    degree: "Dr.med.",
    firstName: "",
    lastName: "",
    gender: "",
    city: "",
    street: "",
    region: "",
    phone: "",
    image: "",
    imagePath: "",
    website: "",
    status: "",
    board: false,
  });

  const triggerNotification = () => {
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 3000);
  };

  const triggerSuccessNotification = () => {
    setPreviewImage({ data: "", preview: "" });
    setSuccessNotification(true);
    setTimeout(() => {
      setSuccessNotification(false);
    }, 3000);
  };

  const getGender = (gender) => {
    return gender === "male"
      ? "images/members/placeholderMale.png"
      : "images/members/placeholderFemale.png";
  };

  const uploadProfilePicture = async () => {
    const storageRef = storage.ref();
    const imageRef = storageRef.child(
      "members_images/" + new Date().toUTCString() + previewImage.data.name
    );
    const imageRefData = await imageRef.put(previewImage.data);
    const imagePath = imageRefData.ref._delegate._location.path_;
    const downloadUrl = await imageRefData.ref.getDownloadURL();
    return { downloadUrl, imagePath };
  };

  const previewImageHandler = (event) => {
    if (event.nativeEvent.target.files.length) {
      setPreviewImage({
        preview: URL.createObjectURL(event.nativeEvent.target.files[0]),
        data: event.nativeEvent.target.files[0],
      });
    }
  };

  const generateId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const checkForm = async () => {
    if (
      person.firstName === "" ||
      person.lastName === "" ||
      person.city === "" ||
      person.street === "" ||
      person.region === "" ||
      person.phone === "" ||
      person.status === ""
    ) {
      triggerNotification();
    } else {
      if (previewImage.preview) {
        const { downloadUrl, imagePath } = await uploadProfilePicture();
        firestore
          .collection("members")
          .add({
            image: downloadUrl,
            imagePath,
            degree: person.degree,
            firstName: person.firstName,
            lastName: person.lastName,
            city: person.city,
            street: person.street,
            region: person.region,
            phone: person.phone,
            website: person.website,
            status: person.status,
            board: person.boardMember ?? false,
            id: generateId(),
          })
          .then(() => {
            triggerSuccessNotification();
          });
      } else {
        firestore
          .collection("members")
          .add({
            image: getGender(person.gender),
            degree: person.degree,
            firstName: person.firstName,
            lastName: person.lastName,
            city: person.city,
            street: person.street,
            region: person.region,
            phone: person.phone,
            website: person.website,
            status: person.status,
            board: person.boardMember ?? false,
            id: generateId(),
          })
          .then(() => {
            triggerSuccessNotification();
          });
      }
      formRef.reset();
      console.log(JSON.stringify(person, null, 4));
    }
  };

  return (
    <div className="addBox">
      <div className="addBoxLeft">
        <h4>Alle Mitglieder (A-Z)</h4>
        <div className="membersBox">
          {members.map((el, i) => {
            return (
              <p
                className={`${
                  selectedUser === i + 1 ? "addBoxLeftSelected" : ""
                }`}
                key={i + 1}
                onClick={() => setSelectedUser(i + 1)}
              >
                {`[${i + 1}] ${el.degree} ${el.firstName} ${el.lastName}`}
              </p>
            );
          })}
        </div>
      </div>
      <div className="addBoxRight">
        <div
          className={
            notification
              ? "addBoxNotificationActive"
              : "addBoxNotificationInactive"
          }
        >
          Hinweis: Bitte füllen Sie alle Felder aus!
        </div>
        <div
          className={
            successNotification
              ? "addBoxSuccessActive"
              : "addBoxSuccessInactive"
          }
        >
          Hinweis: Mitglied erfolgreich hinzugefügt!
        </div>
        <form className="addBoxMainRight" ref={(e) => (formRef = e)}>
          <div className="addBox2">
            <h3>Mitglied hinzufügen</h3>
            <hr style={{ width: "5%" }}></hr>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignContent: "center",
              }}
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Titel</Form.Label>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {person.degree}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          setPerson({ ...person, degree: "Dr.med." })
                        }
                      >
                        Dr.med.
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          setPerson({ ...person, degree: "Dipl. Arzt FMH" })
                        }
                      >
                        Dipl. Arzt FMH
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          setPerson({ ...person, degree: "Dipl. Aerztin FMH" })
                        }
                      >
                        Dipl. Aerztin FMH
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Geschlecht</Form.Label>
                <div>
                  <button
                    className={`addBoxButton ${
                      person.gender === "male"
                        ? "activeButtonBox btn-primary"
                        : "inactiveButtonBox btn-light"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setPerson({ ...person, gender: "male" });
                    }}
                  >
                    <FontAwesomeIcon icon={faMars}></FontAwesomeIcon>
                  </button>
                  <button
                    className={`addBoxButton ${
                      person.gender === "female"
                        ? "activeButtonBox btn-primary"
                        : "inactiveButtonBox btn-light"
                    } `}
                    onClick={(e) => {
                      e.preventDefault();
                      setPerson({ ...person, gender: "female" });
                    }}
                  >
                    <FontAwesomeIcon icon={faVenus}></FontAwesomeIcon>
                  </button>
                </div>
              </Form.Group>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className="mb-2">Brustbild</p>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    id="preview-image"
                    onChange={previewImageHandler}
                  />
                  <label
                    style={{
                      border: "1px solid gray",
                      fontSize: "12px",
                      padding: "5px 10px",
                    }}
                    htmlFor="preview-image"
                  >
                    Datei Auswählen
                  </label>
                </div>
                {previewImage.preview && (
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "5px",
                      alignSelf: "center",
                    }}
                    src={previewImage.preview}
                    alt="preview person"
                  />
                )}
              </div>
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                placeholder="Vorname"
                onChange={(e) =>
                  setPerson({ ...person, firstName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                placeholder="Nachname"
                onChange={(e) =>
                  setPerson({ ...person, lastName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>PLZ Stadt</Form.Label>
              <Form.Control
                placeholder="8320 Lichtenberg"
                onChange={(e) => setPerson({ ...person, city: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Strasse</Form.Label>
              <Form.Control
                placeholder="Am Marsowerplatz 292b"
                onChange={(e) =>
                  setPerson({ ...person, street: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Region</Form.Label>
              <Form.Control
                placeholder="Tessin"
                onChange={(e) =>
                  setPerson({ ...person, region: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                placeholder="+041 278 27 38"
                onChange={(e) =>
                  setPerson({ ...person, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Webseite</Form.Label>
              <Form.Control
                placeholder="www.doktor-doe.ch"
                onChange={(e) =>
                  setPerson({ ...person, website: e.target.value })
                }
              />
              <Form.Text className="text-muted">
                Sollte keine Webseite vorhanden sein, lassen Sie das Feld leer.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Mitgliedsstatus</Form.Label>
              <Form.Control
                placeholder="Mitglied / Kassier / Aktuar "
                onChange={(e) =>
                  setPerson({ ...person, status: e.target.value })
                }
              />
              <Form.Text className="text-muted">
                Nur ein Status pro Mitglied möglich.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <FontAwesomeIcon
                className={`boardButton ${
                  person.boardMember ? "boardButtonTrue" : "boardButtonFalse"
                }`}
                icon={person.boardMember ? faCheck : faTimes}
                onClick={() =>
                  setPerson({ ...person, boardMember: !person.boardMember })
                }
              ></FontAwesomeIcon>
              <Form.Label style={{ userSelect: "none" }}>
                Mitglied im Vorstand
              </Form.Label>
            </Form.Group>
            <div className="addBoxSubmit">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  checkForm();
                }}
              >
                Mitglied hinzufügen
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
