import React, { useState, useEffect, useRef } from 'react';
import '../ozone/content2Style.scss';
import '../modalStyle.scss';
import { Modal, Row, Col, ListGroup, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { TimelineLite, Power3 } from 'gsap';
import { userLanguage } from '../../actions/actions';

export default function ContentComponent2({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let image1 = useRef();
  let image2 = useRef();
  let image3 = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut })
        .from(image2, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(image3, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.4)
        .from(
          textSection,
          1.5,
          { x: -50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onExited={() => {
            fullpage.reBuild();
          }}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Die Ozontherapie</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#einleitung"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#einleitung"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Einleitung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#wirkung"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Wirkung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#indikationen"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      3. Indikationen
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#kontraindikationen"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      4. Kontraindikationen
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#ozonherstellung"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      5. Ozonherstellung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#anwendungsmöglichkeiten"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      6. Anwendung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#verbreitung"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      7. Verbreitung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#geschichte"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      8. Geschichte
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#patienten"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      9. Informationen für die Patienten
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane className="modalRightTab" eventKey="#einleitung">
                      <h6>Einleitung</h6>
                      <div>
                        <p>
                          Internistische Ozontherapie wird in mitteleuropäischen
                          Ländern ärztlich seit rund 60 Jahren angewandt und
                          entspricht nach heutigem Wissensstand einer
                          unspezifischen biologischen Reiztherapie, welche über
                          komplexe biochemische Abläufe auf körpereigene
                          Regulations- und Schutzsysteme (Immunsystem,
                          antioxidative Schutzsysteme) modulierend bzw.
                          deblockierend einwirkt und so in vielen Fällen
                          Heilungsprozesse unterstützt. Zusätzlich wird die
                          Sauerstoffversorgung des Organismus verbessert.
                          <br></br> <br></br>Das Verständnis für die erwähnte
                          Wirkungsweise setzt fundierte physiologische,
                          biochemische und medizinische Sachkenntnis voraus. Im
                          Aufsatz „Mechanisms of Action Involved in Ozone
                          Therapy: Is healing induced via a mild oxidative
                          stress?“ (Med Gas Res. 2011; 1:29)
                          <a
                            href="https://www.ncbi.nlm.nih.gov/pubmed/22185664"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Link zur Studie
                          </a>
                          <br></br>
                          legen Sagai und Bocci dar, dass starker oxidativer
                          Stress u.a. den Transkriptionsfaktor{' '}
                          <span>NFKappa B</span> aktiviert und konsekutiv via
                          Aktivierung von COX2, PGE2 sowie diversen Zytokinen
                          Entzündungsprozesse fördern. Grosse Bedeutung verdiene
                          in diesem Zusammenhang dann die Beobachtung, dass
                          milder oxidativer Stress wie er bei der korrekt
                          dosierten Ozontherapie auf Teile des biologischen
                          Systems ausgeübt wird eine Aktivierung des
                          antagonistisch wirkenden Transkriptionsfaktors{' '}
                          <span>Nrf2</span> auslöse. <br></br> <br></br> Nrf2
                          gelangt sodann in den Zellkern wo er mittels
                          Aktivierung der{' '}
                          <span>„Antioxidant Response Elements“</span> auf der
                          DNA die{' '}
                          <span>
                            Produktion verschiedenster antioxidativer Enzyme
                            aktiviert
                          </span>{' '}
                          (z.B. Superoxid-Dismutase, Glutathion-Peroxidase,
                          Glutathion-S-Transferase, Katalase, Heme-Oxidase-1,
                          NADPH-Quinone-Oxidoreduktase und
                          „Heat-Shock-Proteine“). Als weitere durch milden
                          oxidativen Stress aktivierbare Transkriptionsfaktoren
                          sind zu erwähnen: NFAT (nuclear factor of activated
                          T-cells) und AP-1 (Activated Protein-1). Die
                          Wirksamkeit bei vaskulären Erkrankungen dürfte u.a.
                          über die Aktivierung von HIF-1a
                          (Hypoxia-Inducible-Factor 1 Alfa) erklärbar sein.{' '}
                          <br></br> <br></br> Eine derartige z.B. durch
                          therapeutische Ozonapplikation induzierte
                          „antioxidative Aufrüstung“ ermöglicht es demnach,
                          einer als „Chronischer Oxidativen Stress“
                          definierbaren krankmachenden Stoffwechsel-Lage neue
                          Dynamik und Chance zur Wiedererlangung des
                          erforderlichen Gleichgewichts zu bieten. Aufgrund
                          derartiger Vorgänge kann die erfolgreiche Behandlung
                          einer grossen Vielfalt klinisch sehr unterschiedlich
                          in Erscheinung tretender Krankheitszustände erklärt
                          werden. „Reactive Oxygen Species / oxidative stress
                          act as a „second messenger“ in various intracellular
                          signaling pathways“. <br></br> <br></br> Die
                          dargestellten biochemischen/immunologischen Abläufe
                          dürften somit die therapeutische Wirkung von
                          Ozontherapie auch gegen das Fortschreiten von
                          Krankheitsprozessen des neurodegenerativen Bereichs
                          wie Parkinson, Multiple Sklerose und Alzheimerdemenz
                          erklären. <br></br> <br></br> „Persistent inflammation
                          and the generation of reactive oxygen and nitrogen
                          species play pivotal roles in tissue injury during
                          disease pathogenesis and as a reaction to toxicant
                          exposures. The associated oxidative and nitrative
                          stress promote diverse pathologic reactions including
                          neurodegenerative disorders, atherosclerosis, chronic
                          inflammation, cancer, and premature labor and
                          stillbirth. These effects occur via sustained
                          inflammation, cellular proliferation and cytotoxicity
                          and via induction of a pro-angiogenic environment“.{' '}
                          <br></br>
                          <a
                            href="https://pubmed.ncbi.nlm.nih.gov/19656995/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link zur Studie
                          </a>
                        </p>

                        <p>
                          {' '}
                          Hier noch ein Zitat aus: „Molecular Basis of
                          Cardioprotection“ Signal Transduction in Ischemic
                          Pre-, Post-, and Remote Conditioning Gerd Heusch; Circ
                          Res. 2015;116:674-699. DOI:
                          10.1161/CIRCRESAHA.116.305348.)
                          <br></br> <br></br>
                          ROS (= Reaktive Sauerstoff-Radikale) have an
                          ambivalent role in the conditioning phenomena: Whereas
                          excess formation of ROS contributes to irreversible
                          injury - small amounts of ROS, for example, in
                          response to mitochondrial ATP-dependent potassium
                          channel KATP activation or mitochondrial permeability
                          transition pore MPTP opening contribute to protection,
                          possibly through oxidation of protective cytosolic
                          kinases.{' '}
                          <span>
                            ROS share the same paradox with the conditioning
                            phenomena per se, in that a little
                            ischemia/reperfusion (or ROS) protects, whereas more
                            profound ischemia/reperfusion (or ROS formation)
                            induces injury.
                          </span>{' '}
                          <br></br> Reactive nitrogen species, notably nitric
                          oxide (NO), also share this dose-dependent paradox.
                          Small concentrations of NO improve ventricular
                          function and the matching of oxygen consumption to
                          contractile function, whereas high concentrations of
                          NO depress contractile function.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#wirkung">
                      <h6>Wirkung</h6>
                      <div>
                        <p>
                          Die Internistische Ozontherapie entfaltet ihre Wirkung
                          an Enzymen und Mediatoren (Zytokinen) des
                          Zellstoffwechsels. Konkret handelt es sich dabei um{' '}
                          <br></br>
                          <br></br> A) die körpereigenen (endogenen)
                          antioxidativen Schutzsysteme mit messbarer Anhebung
                          verschiedener antioxidativer Enzyme
                          (Superoxiddismutase, Katalase, Glutathion-Peroxidase,
                          Reduktase)<br></br>
                          <br></br> B) das Immunsystem mit Freisetzung
                          körpereigener Zytokine wie Interferone und
                          Interleukine <br></br>
                          <br></br> C) die Aktivierungs- und Supressorsysteme
                          der Gen-Regulation. Siehe hierzu die folgende „in
                          vivo“ Studie (publiziert 2014): Is ozone
                          pre-conditioning effect linked to Nrf2/EpRE activation
                          pathway in vivo? <br></br>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="modalRightTab"
                      eventKey="#indikationen"
                    >
                      <h6>Indikationen</h6>
                      <div>
                        <p>
                          1. Behandlung und Prophylaxe von chronischen und
                          akuten Entzündungen und Infektionen (sowohl viral als
                          auch bakteriell)<br></br>
                          <br></br> 2. Durchblutungsstörungen <br></br>
                          <br></br> 3. Degenerative Alterserkrankungen, wie zum
                          Beispiel "Maculardegeneration" <br></br>
                          <br></br> 4. Als Begleitbehandlung zu onkologischen
                          Erkrankungen
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="modalRightTab"
                      eventKey="#kontraindikationen"
                    >
                      <h6>Kontraindikationen</h6>
                      <div>
                        <p>
                          1. Favismus<br></br>
                          <br></br> 2. Schwangerschaft<br></br>
                          <br></br> 3. Bestimmte Leukämien <br></br>
                          <br></br> 4. Relative Kontraindikationen bei
                          Autoimmunkrankheiten
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="modalRightTab"
                      eventKey="#ozonherstellung"
                    >
                      <h6>Ozonherstellung</h6>
                      <div>
                        <p>
                          Das Ozonmolekül als hoch reaktionsfreudiges
                          Sauerstoffgebilde wird aus reinem Medizinal-Sauerstoff
                          mittels eines speziellen elektrischen Ozongenerators
                          (Hochvoltkammer, stille elektrische Entladung)
                          unmittelbar vor seiner medizinischen Verwendung
                          hergestellt und durch verantwortungsbewusste,
                          geschulte Therapeuten gezielt eingesetzt. <br></br>
                          <br></br> Weil es therapeutisch unmittelbar an seinen
                          Wirkungsort gebracht wird und dort innert kürzester
                          Zeit reagiert und abgebaut wird entsteht durch korrekt
                          durchgeführte Ozontherapie keine Umweltbelastung.{' '}
                          <br></br>
                          <br></br> Bei korrekter Anwendung ist die Therapie
                          nebenwirkungsfrei und ungefährlich. Die entsprechende
                          Ausbildung und Zertifikatserteilung der ärztlichen
                          Anwender erfolgt in der Schweiz unter Begleitung der
                          SAGOS.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="modalRightTab"
                      eventKey="#anwendungsmöglichkeiten"
                    >
                      <h6>Anwendungsmöglichkeiten</h6>
                      <div>
                        <p>
                          Die internistische Ozontherapie wird mehrheitlich in
                          Form der „Auto-Haemo-Therapie“ durchgeführt (AHT, auch
                          „Grosse Ozon-Eigenblutbehandlung“ GEB genannt).
                          Patientenblut wird steril entnommenem und
                          anschliessend in einer Flasche, also „extrakorporell“
                          mit einer ad hoc hergestellten Mischung aus
                          Sauerstoff- und Ozongas zusammengeführt. Die so
                          behandelte und durch Ozonbeigabe vielfältig veränderte
                          kleine Menge von Eigenblut (0,5 bis 1dl) wird
                          anschliessend durch das belassene Entnahmebesteck in
                          geschlossenem System völlig steril reinfundiert.
                          <br></br>
                          <br></br>
                          Gelegentlich wird Ozontherapie auch als reines
                          „Gas-Klistier“ (R.I. = rektale Insufflation) - also
                          ohne Venenpunktion und ohne Blutkontakt - direkt in
                          den Enddarm appliziert. Bei dieser Anwendungsart wird
                          die dreifache Ozonmenge verabreicht. Mittels vieler
                          Studien konnte belegt werden, dass die rektale
                          Insufflation durchaus eine valable Verabreichungsart
                          darstellt. Ferner kann das Ozon-Sauerstoff-Gemisch
                          mittels intramuskulärer Injektionen oder auch mittels
                          direkter Punktierung in die Gelenke und - durch
                          qualifizierte Fachpersonen unter CT-Bildgebung
                          durchgeführt - gar direkt an die Nervenwurzeln oder in
                          die Bandscheiben injiziert werden.
                          <br></br>
                          <br></br>
                          Nebst der „internistischen“ Ozontherapie wird auch die
                          „äusserliche“ Ozon-Anwendung in Form von Beutel- oder
                          Glocken-Unterdruck-Begasung praktiziert. Diese ist je
                          nach Situation anfänglich deutlich stärker dosiert und
                          kommt bei infizierten oder schlecht heilenden Wunden
                          zum Einsatz. Sie hat gemäss etlicher Therapieberichte
                          schon manche drohende Amputation verhindern können.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#verbreitung">
                      <h6>Internationale Verbreitung</h6>
                      <div>
                        <p>
                          Eine breite internationale Forschergemeinschaft
                          beschäftigt sich seit vielen Jahren mit der
                          therapeutischen Wirkung von Ozon. Die ersten
                          Anwendungen in der Medizin sind bereits in den 50-er
                          Jahren des vergangenen Jahrhunderts erfolgt. <br></br>
                          <br></br> Derzeit wird Ozontherapie in der Schweiz,
                          Deutschland, Italien, Oesterreich, Spanien und in
                          vielen weiteren Ländern (Türkei, Aegypten,
                          Israel,Russland, Indien, China, Indonesien)
                          regelmässig und mit gutem Erfolg angewandt. Gerade
                          auch Länder für welche die sehr hohen Preise moderner
                          antiviraler Medikamente (Hepatitis B und C) oder
                          Monoklonaler Antikörper (Autoimmunerkrankungen wir
                          Rheumatoide Arthritis) finanziell eine sehr grosse
                          Belastung darstellen zeigen grosses Interesse an
                          dieser Therapiemethode. Diese Länder tragen oft mit
                          klinischen Studien wertvolle Beiträge zur
                          wissenschaftlichen Erforschung dieser Therapiemethode
                          bei. <br></br>
                          <br></br> Interessante Pathophysiologie- und
                          Therapiestudien wurden u.a. in Aegypten, Israel und
                          Kuba durchgeführt. Siehe z.B.:
                          <br></br>
                          <a
                            href="https://onlinelibrary.wiley.com/doi/full/10.1111/j.1432-2277.2007.00568.x"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Link zur Referenz-1
                          </a>
                          <br></br>
                          <a
                            href="https://onlinelibrary.wiley.com/doi/full/10.1111/j.1432-2277.2007.00568.x"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Link zur Referenz-2
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#geschichte">
                      <h6>Geschichte der Ozontherapie</h6>
                      <div>
                        <p>
                          Ozon wurde in den Fünfzigerjahren des vorigen
                          Jahrhunderts aufgrund der bereits früh erkannten
                          Verbesserung der Sauerstoffversorgung sowie in
                          Kenntnis der stark desinfizierenden Wirkung als eine
                          Art „Supersauerstoff“ betrachtet. Dagegen wissen wir
                          heute dass seine hauptsächliche therapeutisch nutzbare
                          Wirkungsweise sehr vielschichtig ist.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#patienten">
                      <h6>Informationen für die Patienten</h6>
                      <div>
                        <p>
                          Eine gut gestaltete Broschüre für Patientinnen und
                          Patienten bietet die deutsche Schwestergesellschaft
                          der SAGOS in deutscher, englischer und spanischer
                          Sprache als .pdf zum download an unter diesem Link:
                        </p>
                        <a
                          href="http://www.ozongesellschaft.de/pdf/informationen.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Bezugsmöglichkeit
                        </a>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Das Element Ozon in Detail
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center contentRow2">
        <div className="textBorderRight" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-8 leftSide">
          <img
            className="absoluteImage2-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + 'images/background/ozon2.1.svg'}
            alt="doctors"
          ></img>
          <img
            className="absoluteImage2-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + 'images/background/ozon2.2.svg'}
            alt="doctors"
          ></img>
          <img
            className="absoluteImage2-3 imageHide"
            ref={(el) => (image3 = el)}
            src={process.env.PUBLIC_URL + 'images/background/ozon2.3.svg'}
            alt="doctors"
          ></img>
        </div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Warum und wofür benötigt man die <span>Ozontherapie?</span>
          </h1>
          <p>
            „Ozon“ assoziieren wir spontan mit „Gesundheitsgefährdung“
            „Umweltverschmutzung“ „Ozonloch “ (in der Stratosphäre) „schädliche
            UV-Belastung“. Bei den Erwägungen ob - und in welcher Form - Ozon
            auch therapeutisch einsetzbar sei bieten derartige spontane
            Assoziazionen natürlich erhebliche Hürden.
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
