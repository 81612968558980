import React, { useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Navbar from '../navbarComponent';
import Content3 from '../members/membersContent3';
import Content4 from '../members/membersContent4';
import Content5 from '../members/membersContent5';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default function Section3members() {
  const sectionIdentifier = 'membersarea';
  const memberAuth = useSelector((state) => state.memberAuth);

  const [trigger1, setTrigger1] = useState();
  const [trigger2, setTrigger2] = useState();
  const [trigger3, setTrigger3] = useState();

  const onLeave = (origin, destination, direction) => {
    switch (destination.index) {
      case 1: {
        setTrigger2(true);
        break;
      }
      case 2: {
        setTrigger3(true);
        break;
      }

      default: {
        break;
      }
    }
  };

  const afterLoad = (origin, destination, direction) => {
    if (destination.index === 0) {
      setTrigger1(true);
    }
  };

  return (
    <>
      {memberAuth ? (
        <>
          <Navbar section={sectionIdentifier}></Navbar>
          <ReactFullpage
            licenseKey={'3209920D-4FB74B94-99CE498A-B398312E'}
            scrollingSpeed={1500}
            onLeave={onLeave}
            afterLoad={afterLoad}
            verticalCentered={false}
            navigation={true}
            navigationPosition={'left'}
            navigationTooltips={['Kongresse', 'Grundsätzliches', 'Infos']}
            showActiveTooltip={true}
            render={({ state, fullpageApi }) => {
              return (
                <>
                  <ReactFullpage.Wrapper>
                    <div className="section sectionGeneralStyle">
                      <div className="container d-flex h-100">
                        <Content3
                          fullpage={fullpageApi}
                          animation={trigger1}
                        ></Content3>
                      </div>
                    </div>
                    <div className="section sectionGeneralStyle">
                      <Navbar
                        section={sectionIdentifier}
                        fullpage={fullpageApi}
                      ></Navbar>
                      <div className="container d-flex h-100">
                        <Content4
                          fullpage={fullpageApi}
                          animation={trigger2}
                        ></Content4>
                      </div>
                    </div>
                    <div className="section sectionGeneralStyle">
                      <Navbar
                        section={sectionIdentifier}
                        fullpage={fullpageApi}
                      ></Navbar>
                      <div className="container d-flex h-100">
                        <Content5 animation={trigger3}></Content5>
                      </div>
                    </div>
                  </ReactFullpage.Wrapper>
                </>
              );
            }}
          />
        </>
      ) : (
        <>
          <Redirect to="/"></Redirect>
        </>
      )}
    </>
  );
}
