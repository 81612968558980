export function userAutheticated(userAuth) {
  return {
    type: 'USER_AUTH',
    payload: userAuth,
  };
}

export function userLanguage(userLang) {
  return {
    type: userLang,
  };
}
