import React, { useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Navbar from '../navbarComponent';
import Content1 from '../members/membersContent1';
import '../sagos/section2Style.scss';

export default function MembersSectionWrapper() {
  const sectionIdentifier = 'mitglieder';

  const [trigger1, setTrigger1] = useState(false);

  const afterLoad = (origin, destination, direction) => {
    if (destination.index === 0) {
      setTrigger1(true);
    }
  };

  return (
    <ReactFullpage
      licenseKey={'3209920D-4FB74B94-99CE498A-B398312E'}
      scrollingSpeed={1500}
      verticalCentered={false}
      afterLoad={afterLoad}
      navigation={true}
      navigationPosition={'left'}
      navigationTooltips={['Login']}
      showActiveTooltip={true}
      render={({ state, fullpageApi }) => {
        return (
          <>
            <Navbar section={sectionIdentifier}></Navbar>
            <ReactFullpage.Wrapper>
              <>
                <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content1
                      fullpageApi={fullpageApi}
                      animation={trigger1}
                    ></Content1>
                  </div>
                </div>
              </>
            </ReactFullpage.Wrapper>
          </>
        );
      }}
    />
  );
}
