import React, { useState } from "react";
import "../addUsers/addStyle.scss";
import { Button } from "react-bootstrap";

export default function Remove({ members, firestore, storage }) {
  const [selectedUser, setSelectedUser] = useState(0);
  const [successNotification, setSuccessNotification] = useState(false);
  const [userInfo, setUserInfo] = useState({
    degree: "",
    firstName: "",
    lastName: "",
    city: "",
    street: "",
    region: "",
    phone: "",
    website: "",
    status: "",
    board: "",
    image: "",
    imagePath: "",
    id: "",
  });
  const [notification, setNotification] = useState(false);
  let [currentMembers, setCurrentMembers] = useState([...members]);

  const triggerSuccessNotification = () => {
    setSuccessNotification(true);
    setTimeout(() => {
      setSuccessNotification(false);
    }, 3000);
  };

  const triggerNotification = () => {
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 3000);
  };

  const checkForm = () => {
    if (Object.keys(userInfo).length !== 0) {
      firestore
        .collection("members")
        .where("id", "==", userInfo.id)
        .limit(1)
        .get()
        .then((query) => {
          const document = query.docs[0];
          if (userInfo.imagePath) {
            const storageRef = storage.ref();
            const imageRef = storageRef.child(userInfo.imagePath);
            imageRef.delete().then(() => {
              document.ref.delete().then(() => {
                triggerSuccessNotification();
                const newMembers = currentMembers.filter(
                  (a) => a.id !== userInfo.id
                );
                setCurrentMembers(newMembers);
                setUserInfo({
                  degree: "",
                  firstName: "",
                  lastName: "",
                  city: "",
                  street: "",
                  region: "",
                  phone: "",
                  website: "",
                  status: "",
                  board: "",
                  image: "",
                  imagePath: "",
                  id: "",
                });
              });
            });
          } else {
            document.ref.delete().then(() => {
              triggerSuccessNotification();
              const newMembers = currentMembers.filter(
                (a) => a.id !== userInfo.id
              );
              setCurrentMembers(newMembers);
              setUserInfo({
                degree: "",
                firstName: "",
                lastName: "",
                city: "",
                street: "",
                region: "",
                phone: "",
                website: "",
                status: "",
                board: "",
                image: "",
                imagePath: "",
                id: "",
              });
            });
          }
        });
      setSelectedUser(0);
    } else {
      triggerNotification();
    }
  };

  return (
    <div className="addBox">
      <div className="addBoxLeft">
        <h4>Alle Mitglieder (A-Z)</h4>
        <div className="membersBox">
          {currentMembers.map((el, i) => {
            return (
              <p
                className={`${
                  selectedUser === i + 1 ? "addBoxLeftSelected" : ""
                }`}
                key={i + 1}
                onClick={() => {
                  setSelectedUser(i + 1);
                  setUserInfo({
                    degree: el.degree,
                    firstName: el.firstName,
                    lastName: el.lastName,
                    city: el.city,
                    street: el.street,
                    region: el.region,
                    phone: el.phone,
                    website: el.website,
                    status: el.status,
                    board: el.board,
                    image: el.image,
                    imagePath: el.imagePath,
                    id: el.id,
                  });
                }}
              >
                {`[${i + 1}] ${el.degree} ${el.firstName} ${el.lastName}`}
              </p>
            );
          })}
        </div>
      </div>
      <div className="addBoxRight">
        <form className="addBoxMainRight">
          <div
            className={
              notification
                ? "addBoxNotificationActive"
                : "addBoxNotificationInactive"
            }
          >
            Hinweis: Bitte wählen Sie ein Mitglied aus, der gelöscht werden
            soll!
          </div>
          <div
            className={
              successNotification
                ? "addBoxSuccessActive"
                : "addBoxSuccessInactive"
            }
          >
            Hinweis: Mitglied erfolgreich gelöscht!
          </div>
          <h3>Mitglied löschen</h3>
          <hr style={{ width: "5%" }}></hr>

          <div className="addBox2">
            {userInfo.degree === "" ? (
              <p style={{ textAlign: "center" }}>
                Bitte wählen Sie ein Mitglied aus, der aus dem Verzeichnis
                gelöscht werden soll.
              </p>
            ) : (
              <h4
                style={{ textAlign: "center" }}
              >{`${userInfo.degree} ${userInfo.firstName} ${userInfo.lastName}`}</h4>
            )}
            <div className="addBoxSubmit">
              <Button
                variant="danger"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  checkForm();
                }}
              >
                Mitglied löschen
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
