import React, { useState, useEffect, useRef } from 'react';
import '../members/membersContent4Style.scss';
import { Modal, Row, Col, ListGroup, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { TimelineLite, Power3 } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { userLanguage } from '../../actions/actions';

export default function MembersContent4({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, {
        x: 50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: -50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          onExited={() => {
            fullpage.reBuild();
          }}
          show={show}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Wichtige Informationen</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#infos"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#infos"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Informationen
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#downloads"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Downloads
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane className="modalRightTab" eventKey="#infos">
                      <h6>Tariflisten, Versicherungen</h6>
                      <div>
                        <p>
                          Tariflisten verstossen in der Schweiz gegen das
                          Kartellgesetz und sind daher obsolet. <br></br>{' '}
                          <br></br> Ozontherapie ist bei korrekter Indikation
                          zweckmässig, wirksam und wirtschaftlich. Zumindest die
                          Zusatzversicherungen sollten dies anerkennen!{' '}
                          <br></br> <br></br> Es ist notwendig, dass wir uns bei
                          den Versicherungen immer wieder z.B. unter Verweis auf
                          diese site der SAGOS für die Kostenübernahme
                          einsetzen.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#downloads">
                      <h6>Dokumente</h6>
                      <div>
                        <p>
                          Bei Fragen rund um die Krankenversicherung können Sie
                          uns jederzeit kontaktieren: <br></br>Email:
                          <span> kontakt@sagos.info</span>
                          <br></br>
                          <br></br>
                          Download:
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/sagosweb-e50b5.appspot.com/o/DosierungstabellenGuidelines.pdf?alt=media&token=ff5bc58e-6bd6-4405-b611-2c200cab8397"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Dosierungstabellen.pdf
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Grundsätzliche Informationen
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center membersRow4">
        <div className="textBorderRight" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-8">
          <img
            className="membersImage2-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + 'images/background/members2.1.svg'}
            alt="doctors"
          ></img>
          <img
            className="membersImage2-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + 'images/background/members2.2.svg'}
            alt="doctors"
          ></img>
        </div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Wichtige Informationen für <span>Mitglieder</span>
          </h1>
          <p>
            Tariflisten verstossen in der Schweiz gegen das Kartellgesetz und
            sind daher obsolet. Ozontherapie ist bei korrekter Indikation
            zweckmässig, wirksam und wirtschaftlich. Zumindest die
            Zusatzversicherungen sollten dies anerkennen!
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
