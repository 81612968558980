import React, { useEffect, useRef } from 'react';
import '../sagos/sagosContent6Style.scss';
import { TimelineLite, Power3 } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { userLanguage } from '../../actions/actions';
import { useHistory } from 'react-router-dom';

export default function SagosContent6({ animation }) {
  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();
  let image3 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  const history = useHistory();

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, { opacity: 0, ease: Power3.easeOut })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(image3, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          history.push('/mitglieder');
        }}
      >
        <h1>
          Nächster Abschnitt: <br></br>Mitgliederbereich
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center sagosRow6">
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            <span>Informieren</span> Sie sich <br></br> noch heute!
          </h1>
          <p>
            Ozontherapie findet seit vielen Jahren wissenschaftliche Erforschung
            und therapeutische Anwendung. Die Wirkungsweise ist gut belegt.
            Ozontherapie ist bei korrekter Anwendung gut verträglich, nicht
            toxisch und völlig ungefährlich.
          </p>
          <p>
            Unsere Kontaktdaten:
            <br></br>
            Email: <span className="emailHighlight">kontakt@sagos.info</span>
          </p>
        </div>
        <div className="col-md-8">
          {' '}
          <img
            className="sagosImage7-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + 'images/background/info1.1.svg'}
            alt="doctors"
          ></img>
          <img
            className="sagosImage7-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + 'images/background/info1.2.svg'}
            alt="doctors"
          ></img>
          <img
            className="sagosImage7-3 imageHide"
            ref={(el) => (image3 = el)}
            src={process.env.PUBLIC_URL + 'images/background/info1.3.svg'}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
