import React, { useState, useEffect, useRef } from "react";
import "../members/membersAdmin.scss";
import { TimelineLite, Power3, TweenMax } from "gsap";
import Add from "../addUsers/add";
import Update from "../addUsers/update";
import Remove from "../addUsers/remove";
import firebase from "firebase";

export default function MembersContentAdmin({ fullpage, animation }) {
  let tl = new TimelineLite({ delay: 0.3 });
  let rowSection = useRef();
  const [currentTab, setCurrentTab] = useState("add");
  const [members, setMembers] = useState([]);
  const [loaded, setDataLoaded] = useState(false);
  const [userAuth, setUserAuth] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const adminPassword = "bühler1234";
  const [warningNotification, setWarningNotification] = useState(false);

  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyAu8r9P_43YLkv5aNAP85Cg6I42Ew5MNHI",
      authDomain: "sagosweb-e50b5.firebaseapp.com",
      databaseURL: "https://sagosweb-e50b5.firebaseio.com",
      projectId: "sagosweb-e50b5",
      storageBucket: "sagosweb-e50b5.appspot.com",
      messagingSenderId: "713864211879",
      appId: "1:713864211879:web:55f2c7b6e585dbdb7fda82",
    });
  } else {
    firebase.app();
  }
  let db = firebase.firestore();
  let storage = firebase.storage();

  const checkPassword = () => {
    inputPassword === adminPassword ? setUserAuth(true) : triggerNotification();
  };

  useEffect(() => {
    db.collection("members")
      .get()
      .then((querySnapshot) => {
        const membersData = [];
        querySnapshot.forEach((el) => {
          membersData.push(el.data());
        });
        const sortedMembers = membersData.sort((a, b) => {
          if (b.lastName > a.lastName) {
            return -1;
          } else return null;
        });
        setMembers(sortedMembers);
        setDataLoaded(true);
      });
  }, [db]);

  const triggerNotification = () => {
    setWarningNotification(true);
    setTimeout(() => {
      setWarningNotification(false);
    }, 3000);
  };

  const EnterKey = (key, cb) => {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handler(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handler);
      return () => document.removeEventListener("keypress", handler);
    }, [key]);
  };

  EnterKey("Enter", checkPassword);

  useEffect(() => {
    if (!userAuth) return;
    TweenMax.to(rowSection, 0, { css: { visibility: "visible" }, delay: 0.3 });
  }, [userAuth]);

  useEffect(() => {
    if (!userAuth) return;
    if (animation) {
      tl.from(rowSection, 1.5, {
        y: 50,
        opacity: 0,
        ease: Power3.easeOut,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      {!userAuth ? (
        <div className="loginContainerMain">
          <div
            className={
              warningNotification
                ? "warningNotificationActive"
                : "warningNotificationInactive"
            }
          >
            Hinweis: Das eingegebene Passwort ist falsch. Bitte geben Sie das
            Passwort erneut ein!
          </div>
          <h5>Bitte geben Sie das Passwort für den Adminbereich ein</h5>
          <hr style={{ width: "50px" }} />
          <div className="loginButtonBox">
            <input
              type="password"
              onChange={(e) => setInputPassword(e.target.value)}
            ></input>
            <button onClick={() => checkPassword()}>Anmelden</button>
          </div>
        </div>
      ) : (
        <div className="containerMain" ref={(el) => (rowSection = el)}>
          <div className="adminBox">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className={`nav-item nav-link ${
                    currentTab === "add" ? "active" : ""
                  }`}
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#hinzufuegen"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected={currentTab === "add"}
                  onClick={() => setCurrentTab("add")}
                >
                  Mitglied hinzufügen
                </a>
                <a
                  className={`nav-item nav-link ${
                    currentTab === "update" ? "active" : ""
                  }`}
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#bearbeiten"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected={currentTab === "update"}
                  onClick={() => setCurrentTab("update")}
                >
                  Mitglied bearbeiten
                </a>
                <a
                  className={`nav-item nav-link ${
                    currentTab === "remove" ? "active" : ""
                  }`}
                  id="nav-contact-tab"
                  data-toggle="tab"
                  href="#loeschen"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected={currentTab === "remove"}
                  onClick={() => setCurrentTab("remove")}
                >
                  Mitglied löschen
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className={`tab-pane fade ${
                  currentTab === "add" ? "show active" : ""
                }`}
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {loaded ? (
                  <Add members={members} firestore={db} storage={storage}></Add>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`tab-pane fade ${
                  currentTab === "update" ? "show active" : ""
                }`}
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                {loaded ? (
                  <Update
                    members={members}
                    firestore={db}
                    storage={storage}
                  ></Update>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`tab-pane fade ${
                  currentTab === "remove" ? "show active" : ""
                }`}
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                {loaded ? (
                  <Remove
                    members={members}
                    firestore={db}
                    storage={storage}
                  ></Remove>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
