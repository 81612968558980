import React from 'react';

export default function Impressum() {
  return (
    <>
      <h4>Kontakt-Adresse</h4>
      <p>
        SAGOS
        <br />
        Spiezbergstrasse 18 <br />
        3700 Spiez
        <br />
        Schweiz
      </p>
      <p>
        E-Mail:
        <br />
        kontakt@sagos.info
      </p>
      <h4>Vertretungsberechtigte Person(en)</h4>
      <p>Dr. med. Adrian B&uuml;hler</p>
      <br />
      <h4>Haftungsausschluss</h4>
      <p>
        Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich der
        inhaltlichen Richtigkeit, Genauigkeit, Aktualit&auml;t,
        Zuverl&auml;ssigkeit und Vollst&auml;ndigkeit der Informationen.
      </p>
      <p>
        Haftungsanspr&uuml;che gegen den Autor wegen Sch&auml;den materieller
        oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
        Nichtnutzung der ver&ouml;ffentlichten Informationen, durch Missbrauch
        der Verbindung oder durch technische St&ouml;rungen entstanden sind,
        werden ausgeschlossen.
      </p>
      <p>
        Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich
        ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
        besondere Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
        l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder
        endg&uuml;ltig einzustellen.
      </p>
      <h4>Haftungsausschluss f&uuml;r Links</h4>
      <p>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
        Verantwortungsbereichs. Es wird jegliche Verantwortung f&uuml;r solche
        Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
        erfolgen auf eigene Gefahr des jeweiligen Nutzers.
      </p>
      <h4>Urheberrechte</h4>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
        anderen Dateien auf dieser Website, geh&ouml;ren ausschliesslich{' '}
        <strong>der juristischen Person SAGOS</strong> oder den speziell
        genannten Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente
        ist die schriftliche Zustimmung des Urheberrechtstr&auml;gers im Voraus
        einzuholen.
      </p>
      <p>
        Spiez, 15.10.2020
        <br />
        Quelle:{' '}
        <a
          href="https://www.swissanwalt.ch"
          target="_blank"
          rel="noopener noreferrer"
        >
          SwissAnwalt
        </a>
      </p>{' '}
    </>
  );
}
