import React, { useState, useEffect, useRef } from 'react';
import '../ozone/content5Style.scss';
import '../modalStyle.scss';
import { Modal, Row, Col, ListGroup, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TimelineLite, Power3 } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { userLanguage } from '../../actions/actions';

export default function ContentComponent5({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    tl.from(image1, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut })
      .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
      .from(textSection, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.6)
      .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
      .from(
        languageSection,
        1.5,
        { x: 50, opacity: 0, ease: Power3.easeOut },
        0.8
      )
      .from(
        scrollSection,
        1.5,
        { y: 50, opacity: 0, ease: Power3.easeOut },
        0.8
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          onExited={() => {
            fullpage.reBuild();
          }}
          show={show}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Wissenswertes über Ozon</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#ozon"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#ozon"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Ozon in Praxis
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#erklärung"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Erklärung
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#mechanismen"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      3. Mechanismen
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#chemie"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      4. Chemie
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#zahnmedizin"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      5. Zahnmedizin
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane className="modalRightTab" eventKey="#ozon">
                      <h6>Ozon in der Praxis</h6>
                      <div>
                        <p>
                          Ozon findet seit über 50 Jahren in Europa medizinisch
                          Verwendung. Gemäss neueren Untersuchungen über Erfolge
                          und Wirkmechanismen von Ozon-Eigenblutbehandlungen
                          darf die korrekt ausgeübte Ozontherapie als weitgehend
                          nebenwirkungsfrei und ungefährlich eingestuft werden.{' '}
                          <br></br>
                          <br></br>
                          In seinem Buch „OZONE A NEW MEDICAL DRUG“ (Springer,
                          2005; 234 S.; mit ca. 1000 Referenzangaben) bestätigt
                          der Arzt und forschende Professor für Physiologie
                          Velio Bocci aus Siena: <br></br>
                          „After performing millions of
                          Ozone-Auto-Haemo-Therapies during the last decades we
                          can assure that ozonetherapy does not procure any
                          adverse effects but actually improves the quality of
                          life of the majority of patiens“. In vielen Ländern
                          des europäischen Kontinents, im nahem Osten, den USA,
                          Südamerika und Asien wird Ozontherapien von einer
                          grossen Anzahl Aerzten regelmässig durchgeführt. So
                          werden gemäss Dr. Ni Jiaxiang im Dpt. of Pain
                          Management der Capital University in Peking jährlich
                          über 10 000 ambulante Behandlungen mit Ozon
                          durchgeführt. Komplikationen, welche auf Ozon
                          zurückzuführen wären, seien keine beobachtet worden
                          (mitgeteilt im Okt. 2010 in Wien). Siehe hierzu:
                          <br></br>
                          <br></br>
                          Ozontherapie wird nie über die Atemwege appliziert.
                          Bei korrekter Anwendung verursacht sie keine Belastung
                          der Atemwege. Medizinisch verwendetes Ozon ist ein
                          Gemisch aus einem sehr kleinen Anteil frisch
                          hergestellten reinsten Ozons und reinem Sauerstoff.
                          Die Ozonkonzentration variiert je nach Anwendungsart
                          und Krankheitsbild von 1-100 Mikrogramm/ml
                          (entsprechend 0,05% bis max. 5% 0zongehalt).
                          <br></br>
                          <br></br>
                          <h2>Ozon Sauerstoff Therapie:</h2>
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/sagosweb-e50b5.appspot.com/o/ozonErkla%CC%88rung.pdf?alt=media&token=4e89b913-666f-4366-b415-e2b373693deb"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            OzonErklärung.pdf
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#erklärung">
                      <h6>Was ist Ozon?</h6>
                      <div>
                        <p>
                          Ozon ist eine besondere chemische Form von Sauerstoff.
                          Normales Sauerstoffgas besteht aus zwei Atomen
                          Sauerstoff (O2). Ozon dagegen ist die chemisch
                          äusserst reaktionsfreudige Form von drei miteinander
                          verbundenen Sauerstoff-Atomen (O3). „Atomarer“
                          Sauerstoff, das heißt Sauerstoff in Form freier,
                          einzelner Sauerstoffatome, kommt stabil nur unter
                          extremen Bedingungen vor. Ozon kann unter natürlichen
                          Bedingungen unter Einfluss von UV-Licht aus
                          Stickstoffdioxid und Sauerstoff entstehen: <br></br> 2
                          NO + O2 &rarr; 2 N O2 ; N O2 + Licht &rarr; NO + O.{' '}
                          <br></br> Der Sauerstoff mit ungepaartem Elektron (O.)
                          reagiert dann mit O2 weiter: + O2 O3 . Das so
                          entstandene Ozon ist ein geläufiger Indikator für die
                          Abgasbelastung der Umwelt und wird daher
                          fälschlicherweise gerne mit „Umweltgift“
                          gleichgesetzt. <br></br>
                          <br></br> Siehe hierzu die Ausführungen der Schweiz.
                          Gesellschaft für Pneumologie
                          <br></br> Ozon für die gezielte Nutzung in der Medizin
                          wird aus reinstem medizinischem Sauerstoff in
                          speziellen Hochvoltgeräten erzeugt. Er ist sehr
                          „kurzlebig“, d.h. er zerfällt wieder in den
                          Ausgangsstoff O2. Bereits 1857 hat Werner von Siemens
                          die erste technische Apparatur zur Erzeugung von Ozon
                          gebaut. <br></br>
                          <br></br> Ozon ist für den Menschen geruchlich bereits
                          bei der sehr niedrigen Konzentration von 0,01 ppm
                          (parts per million) wahrnehmbar. Die
                          Arbeitsplatzgrenzwerte für Ozon liegen in der
                          Bundesrepublik Deutschland bei 0,1 ppm beziehungsweise
                          200 µg/m3. In der Stratosphäre (25 bis 30 Kilometer
                          über der Erdoberfläche) befinden sich etwa 90 Prozent
                          des natürlich vorkommenden Ozons. In 30 Kilometern
                          Höhe erreicht es die maximale Konzentration von 10
                          ppm. Es absorbiert dort zu unserem Schutz einen
                          beträchtlichen Teil der schädlichen kurzwelliger
                          UV-Strahlen des Sonnenlichts.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#mechanismen">
                      <h6>Wirkungsmechanismen</h6>
                      <div>
                        <p>
                          Im Körper finden chemisch laufend verschiedenste
                          oxidative und reduktive Vorgänge statt. Bei oxidativem
                          Stress überwiegen (lokal) die oxidativen Vorgänge, was
                          bezüglich Alterungsprozessen, degenerativer
                          Entzündungsvorgänge wie gereizter Arthrosen,
                          Arthritis, aber auch Diabetes mit entsprechenden
                          Folgeschäden, Makuladegeneration und anderen Alters-
                          und Zivilationskrankheiten gravierende Folgen hat.
                          <br></br>
                          <br></br>
                          Ursächlich beruht der oxidative Stress auf vermehrter
                          Produktion oder verminderter Elimination von
                          sauerstoffhaltigen Radikalen, welche u.a. bei den
                          lebenswichtigen biochemischen Abläufen der
                          Energiegewinnung in den Mitochondrien oder bei
                          immunologischen Abwehrvorgängen entstehen. Diese
                          ständig als Regulationsmoleküle oder als Nebenprodukte
                          entstehenden Oxidantien müssen umgehend durch das
                          endogene antioxidative Schutzsystem abgefangen und
                          neutralisiert werden. Stichworte hierzu:
                          Sauerstoffradikale; ROS („reactive oxygen species“).{' '}
                          <br></br>
                          <br></br> Ozon bildet bei der internistisch
                          therapeutischen Anwendung im direkten Kontakt mit
                          Lipiden sog. „Peroxide“ (engl. „LOP“ = Lipid Oxidation
                          Products), welche als Induktoren für vielfältige
                          biochemische Reaktionen bekannt sind. Sie bewirken
                          z.B. im Endothel (Innenauskleidung der Blutgefässe)
                          eine vermehrte Bildung von Stickstoffmonoxid und damit
                          eine Gefässerweiterung oder in den Roten
                          Blutkörperchen eine vermehrte Bildung von „2,3-DPG“,
                          was eine bessere Sauerstoffversorgung der Gewebe
                          bewirken kann. Bereits aufgrund dieser mehrfach
                          bewiesenen Wirkungsmechanismen müsste der Ozontherapie
                          volle Anerkennung als wissenschaftlich erforschte,
                          nachvollziehbare Therapiemethode zugestanden werden.
                          <br></br>
                          <br></br>
                          Es kommen aber weitere Wirkmechanismen hinzu: Das
                          „antioxidative Schutzsystem“ der Organismen ist sehr
                          komplex. Zweifellos ist es für die Zellen und
                          Organismen absolut überlebenswichtig. Die Ozontherapie
                          vermag dieses Schutzsystem nachhaltig zu aktivieren,
                          was verschiedene empirisch gefundene therapeutische
                          Anwendungen zu erklären vermag. Von speziellem
                          Interesse sind die Beobachtungen, dass Organschäden
                          nach vorübergehend unterbrochener Blutversorgung
                          mittels Ozontherapie verhindert werden können
                          (Ischämie/Reperfusionsschäden, I/R Injury). Diese
                          Tatsache wurde experimentell sehr intensiv über Jahre
                          hinweg in verschiedensten Versuchsanordnungen
                          nachgewiesen, z. B. durch den italienischen
                          Physiologen Prof. Velio Bocci aus Siena oder die
                          Forschergruppe um Frau Dr. Olga Léon in Kuba.{' '}
                          <br></br>
                          <br></br> Als weiteren Wirkungsmechamismus kennt man
                          denjenigen über das Immunsystem, welches auf
                          Ozontherapie mit vermehrter bzw. modulierter
                          Produktion von Zytokinen (Interleukin, Interferon)
                          reagiert. Diese Wirkung kann zu einer Verbesserung der
                          Immunabwehr bzw. auch zu einer Eindämmung
                          unkontrollierter Entzündungsvorgänge bei bestimmten
                          Autoimmunerkrankungen genutzt werden. <br></br>
                          <br></br> Zytokine sind an der Pathogenese sehr
                          zahlreicher, höchst unterschiedlicher Erkrankungen
                          beteiligt (koronare und zerebrovaskuläre Ereignisse,
                          multiple Sklerose, Alzheimer Demenz, Rheumatoide
                          Arthritis und Morbus Still). Siehe hierzu: Effects of
                          Ozone Oxidative Preconditioning on TNF-α Release and
                          Antioxidant-Prooxidant Intracellular Balance in Mice
                          During Endotoxic Shock: „These results indicate that
                          ozone oxidative preconditioning exerts inhibitory
                          effects on TNF-α production and on the other hand it
                          exerts influence on the antioxidant-prooxidant balance
                          for preservation of cell redox state by the increase
                          of endogenous antioxidant systems. <br></br>
                          <br></br> Die exakte physiologische Bedeutung der
                          einzelnen Zytokine ist noch nicht restlos geklärt.
                          Ihre Funktionen dürften sich teilweise auch
                          überlappen. Entzündungsfördernd wirken z.B. IL-1, TNF.
                          Entzündungshemmend wirken z.B. IL-4. Eine Vermehrung
                          und Differenzierung der T- und B-Lymphozyten erfolgt
                          durch IL-2 und Interferon-gamma. Denselben Effekt auf
                          die hämatologischen Stammzellen haben z.B. IL-3 und
                          G-CSF. Zytokine haben auch eine sog. chemokinetische
                          Wirkung, d.h. sie beeinflussen die Mobilität und
                          Migration von Immunzellen. Wenn ein bakterielles Toxin
                          in den Kreislauf gelangt, werden vom Organismus z.B.
                          hintereinander zuerst TNF, dann Interleukine und
                          schliesslich Chemokine freigesetzt. <br></br>
                          <br></br> Auch die spezifische Beeinflussung der
                          Zytokine durch die Ozontherapie ist noch nicht restlos
                          geklärt. In diesem Sinne muss Ozontherapie - wie viele
                          andere etablierten Therapien auch - immer noch als
                          empirische Therapie betrachtet werden. Dies betrifft
                          jedoch ebenso der Einsatz von Steroiden wie auch
                          nichtsteroidaler Entzündungshemmer und in besonderem
                          Ausmass den der modernen „Biologicals“ wie
                          rekombinanter Interleukine, Interferone, monoklonaler
                          Antikörper gegen TNF-alfa, Interleukin-Antagonisten
                          etc. <br></br>
                          <br></br>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#chemie">
                      <h6>Chemischer Reaktionspartner</h6>
                      <div>
                        <p>
                          Die „Lebensdauer“ eines Ozonmoleküls ist abgesehen von
                          der Temperatur natürlich extrem abhängig von der
                          Anwesenheit chemischer Reaktonspartner. Als starkes
                          Oxidationsmittel ist es sehr reaktionsfreudig und
                          reagiert daher meistens innert Bruchteilen einer
                          Sekunde weiter und verschwindet. Bei Erwärmung ist es
                          feuergefährlich oder gar explosiv. Nach Fluor ist es
                          das stärkste bekannte Oxidationsmittel und es oxidiert
                          auch die meisten Metalle (ausgenommen Edelmetalle). In
                          Abwesenheit weiterer Reaktionspartner zerfällt Ozon
                          bei 20°C mit einer Halbwertzeit von drei Tagen und bei
                          -50°C mit einer Halbwertzeit von drei Monaten spontan
                          unter Energiefreisetzung und Bildung freier Radikale.
                          <br></br>
                          In einer Glasspritze mit 50 ml Volumen bei 20°C
                          beträgt die Halbwertzeit von Ozon ca. 45 Minuten.
                          <br></br>
                          <br></br>
                          Aufgrund seiner hohen Reaktivität ist es auch eines
                          der stärksten Zellgifte. Es wirkt bakterizid, fungizid
                          und viruzid. Für die desinfizierende Wirkung ist
                          feuchtes Milieu Voraussetzung und es werden relativ
                          hohe Dosierungen angewandt. Für die Inaktivierung von
                          Bakterien spielt die Oxidationsreaktion an der
                          Bakterienmembran durch freie Radikale die
                          entscheidende Rolle. Hierbei werden Glykoproteine,
                          Glykolipide und andere Aminosäuren angegriffen, was zu
                          einer Störung der Zellwandpermeabilität mit
                          anschließendem Zelluntergang führt. Bei Viren sind
                          Ansatzpunkte für die Viruzidie das Capsid bzw. die
                          proteinartigen Bestandteile der Hülle (Ozon verändert
                          diese derart, dass sich das Virus nicht mehr an
                          Zelloberflächen anheften kann). Möglicherweise noch
                          wichtiger ist die oxidative Wirkung auf die virale DNA
                          oder RNA mit irreversibler Schädigung durch Ozon. In
                          der medizinisch internistischen Anwendung wird Ozon
                          allerdings so niedrig dosiert, dass derartige
                          Schädigungen nicht stattfinden können. Um zuverlässige
                          desinfizierende Wirkung zu erreichen existieren
                          bezüglich Ozonkonzentration und Einwirkungsdauer
                          Erfahrungswerte. Zu beachten ist dabei dass es
                          natürlich Unterschiede bei Einwirkung auf einzelnen
                          Bakterien oder der Behandlung von geklumpten
                          Bakterienkolonien gibt. <br></br>
                          <br></br> Auf Augen und Schleimhäute wirkt Ozon
                          reizend, in den Atemwegen kann es zu Atembeschwerden
                          mit Abnahme des Respirationsvolumens, Bronchitis und
                          Lungenödem führen. Ozon wird großtechnisch seit vielen
                          Jahrzehnten zur Trinkwasser- und Abwasseraufbereitung
                          sowie zur Desinfektion von Schwimmbadwasser genutzt.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#zahnmedizin">
                      <h6>Ozon in der Zahnmedizin</h6>
                      <div>
                        <p>
                          In der Zahnmedizin wird Ozon zur Wasser- und
                          Wunddesinfektion verwendet. Filippi zeigte, dass
                          ozonisiertes Wasser bei täglicher Applikation in den
                          ersten 48 Stunden postoperativ die epiteliale
                          Wundheilung fördert. Ozoniertes Wasser wird in der
                          Behandlung von Gingivitis und Parodontitis marginalis
                          wie auch zur antimikrobiellen Mundspülung empfohlen.
                          Ein spezielles dentales Ozongerät erzeugt an der
                          Spitze des Therapieansatzes Ozonkonzentrationen
                          zwischen 10 bis 100 µg/ml. Das Gerät wird für die
                          Behandlung der Karies und Parodontitis sowie in der
                          Endodontie empfohlen.
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Grundsätzliche Informationen
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center contentRow5">
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            <span>Wissenswertes</span> über <br></br> die Ozontherapie
          </h1>
          <p>
            Ozon findet seit über 50 Jahren in Europa medizinisch Verwendung.
            Gemäss neueren Untersuchungen über Erfolge und Wirkmechanismen von
            Ozon-Eigenblutbehandlungen darf die korrekt ausgeübte Ozontherapie
            als weitgehend nebenwirkungsfrei und ungefährlich eingestuft werden.
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
        <div className="col-md-8 leftSide">
          <img
            className="absoluteImage5-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + 'images/background/ozon5.1.svg'}
            alt="doctors"
          ></img>
          <img
            className="absoluteImage5-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + 'images/background/ozon5.2.svg'}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
