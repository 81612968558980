import React, { useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Navbar from '../navbarComponent';
import Content1 from '../sagos/sagosContent1';
import ContentJoin from '../sagos/sagosJoin';
import Content2 from '../sagos/sagosContent2';
// import Content3 from '../sagos/sagosContent3';
// import Content4 from '../sagos/sagosContent4';
import Content5 from '../sagos/sagosContent5';
import Content6 from '../sagos/sagosContent6';
import '../sagos/section2Style.scss';

const sectionIdentifier = 'sagos';

export default function SagosSectionWrapper() {
  const [trigger1, setTrigger1] = useState(false);
  const [trigger2, setTrigger2] = useState(false);
  const [trigger3, setTrigger3] = useState(false);
  const [trigger4, setTrigger4] = useState(false);
  const [trigger5, setTrigger5] = useState(false);
  // const [trigger6, setTrigger6] = useState(false);
  // const [trigger7, setTrigger7] = useState(false);

  const onLeave = (origin, destination, direction) => {
    switch (destination.index) {
      case 1: {
        setTrigger2(true);
        break;
      }
      case 2: {
        setTrigger3(true);
        break;
      }
      case 3: {
        setTrigger4(true);
        break;
      }
      case 4: {
        setTrigger5(true);
        break;
      }
      case 5: {
        // setTrigger6(true);
        break;
      }

      case 6: {
        // setTrigger7(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const afterLoad = (origin, destination, direction) => {
    if (destination.index === 0) {
      setTrigger1(true);
    }
  };

  return (
    <>
      <Navbar section={sectionIdentifier}></Navbar>
      <ReactFullpage
        licenseKey={'3209920D-4FB74B94-99CE498A-B398312E'}
        scrollingSpeed={1500}
        onLeave={onLeave}
        afterLoad={afterLoad}
        verticalCentered={false}
        navigation={true}
        navigationPosition={'left'}
        navigationTooltips={[
          'Zweck',
          'Mitglieder',
          'Beitreten',
          'Literatur',
          'Infos',
        ]}
        showActiveTooltip={true}
        render={({ state, fullpageApi }) => {
          return (
            <>
              <ReactFullpage.Wrapper>
                <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content1
                      fullpage={fullpageApi}
                      animation={trigger1}
                    ></Content1>
                  </div>
                </div>
                <div className="section sectionGeneralStyle">
                  <div className="container">
                    <Content2
                      fullpage={fullpageApi}
                      animation={trigger2}
                    ></Content2>
                  </div>
                </div>
                <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <ContentJoin
                      fullpage={fullpageApi}
                      animation={trigger3}
                    ></ContentJoin>
                  </div>
                </div>
                {/* <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content3
                      fullpage={fullpageApi}
                      animation={trigger4}
                    ></Content3>
                  </div>
                </div> */}
                {/* <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content4
                      fullpage={fullpageApi}
                      animation={trigger5}
                    ></Content4>
                  </div>
                </div> */}
                <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content5
                      fullpage={fullpageApi}
                      animation={trigger4}
                    ></Content5>
                  </div>
                </div>
                <div className="section sectionGeneralStyle">
                  <div className="container d-flex h-100">
                    <Content6 animation={trigger5}></Content6>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            </>
          );
        }}
      />
    </>
  );
}
