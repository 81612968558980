const languageReducer = (state = 'deutsch', action) => {
  switch (action.type) {
    case 'deutsch':
      return (state = 'deutsch');
    case 'französisch':
      return (state = 'französisch');
    case 'italienisch':
      return (state = 'italienisch');
    default:
      return state;
  }
};

export default languageReducer;
