import React, { useEffect, useRef } from 'react';
import '../ozone/content1Style.scss';
import '../modalStyle.scss';
import { TimelineLite, Power3, CSSPlugin, gsap, TweenMax } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { userLanguage } from '../../actions/actions';

export default function ContentComponent1({ fullpage, animation }) {
  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  let textSection = useRef();

  let image1 = useRef();
  let image2 = useRef();
  let image3 = useRef();
  let image4 = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let rowSection = useRef();

  let tl = new TimelineLite({ delay: 0.3 });

  useEffect(() => {
    TweenMax.to(rowSection, 0, { css: { visibility: 'visible' }, delay: 0.3 });
  }, []);

  useEffect(() => {
    if (animation) {
      tl.from(image2, 1.5, {
        y: 50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image3, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(image4, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.4)
        .from(image1, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)

        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);
  gsap.registerPlugin(CSSPlugin);

  return (
    <>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Wozu braucht man die Ozontherapie?
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div
        className="row contentRow1 justify-content-center align-self-center"
        ref={(el) => (rowSection = el)}
      >
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Willkommen bei <span> SAGOS</span> <br></br>Schweizerische
            Ärzte-Gesellschaft für Ozontherapie
          </h1>
          <p>
            Ozontherapie findet seit vielen Jahren wissenschaftliche Erforschung
            und therapeutische Anwendung. Die Wirkungsweise ist gut belegt.
            Ozontherapie ist bei korrekter Anwendung gut verträglich, nicht
            toxisch und völlig ungefährlich.
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                fullpage.moveSectionDown();
              }}
            >
              Erfahren Sie mehr
            </button>
          </div>
        </div>
        <div className="col-md-8 leftSide">
          <img
            ref={(el) => (image1 = el)}
            className="absoluteImage1-1 imageHide"
            src={process.env.PUBLIC_URL + 'images/background/ozon1.1.svg'}
            alt="doctors"
          ></img>
          <img
            ref={(el) => (image2 = el)}
            className="absoluteImage1-2 imageHide"
            src={process.env.PUBLIC_URL + 'images/background/ozon1.2.svg'}
            alt="doctors"
          ></img>
          <img
            ref={(el) => (image3 = el)}
            className="absoluteImage1-3 imageHide"
            src={process.env.PUBLIC_URL + 'images/background/ozon1.3.svg'}
            alt="doctors"
          ></img>
          <img
            ref={(el) => (image4 = el)}
            className="absoluteImage1-4 imageHide"
            src={process.env.PUBLIC_URL + 'images/background/ozon1.4.svg'}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
