import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Modal, Tab, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../components/navbarStyle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Datenschutz from "./legal/datenschutz";
import Impressum from "./legal/impressum";
import { useSelector } from "react-redux";
import { TimelineLite, Power3 } from "gsap";
import { News } from "./News";

export default function NavbarComponent({ section }) {
  const [show, setShow] = useState(false);
  const memberAuth = useSelector((state) => state.memberAuth);
  const modalRight = useRef();

  let tl = new TimelineLite({ delay: 0.6 });
  let navbarComponent = useRef();

  useEffect(() => {
    tl.from(navbarComponent, 1.5, {
      opacity: 0,
      ease: Power3.easeOut,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        onExited={() => {
          window.fullpage_api.reBuild();
        }}
        show={show}
        onHide={() => {
          handleClose();
          window.fullpage_api.setAutoScrolling(true);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <FontAwesomeIcon
          className="modalCloseButton"
          icon={faTimes}
          onClick={() => {
            handleClose();
            window.fullpage_api.setAutoScrolling(true);
          }}
        ></FontAwesomeIcon>
        <Modal.Header className="modalHeader">
          <Modal.Title>
            <h2>Generelle Informationen</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#news">
            <Row className="modalRow">
              <Col md={4}>
                <ListGroup className="modalLeft">
                  <ListGroup.Item
                    className="modalLeftItem"
                    action
                    href="#news"
                    onClick={() => {
                      modalRight.current.scrollTop -= 10000;
                    }}
                  >
                    1. News
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="modalLeftItem"
                    action
                    href="#kontakt"
                    onClick={() => {
                      modalRight.current.scrollTop -= 10000;
                    }}
                  >
                    2. Kontakt
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="modalLeftItem"
                    action
                    href="#impressum"
                    onClick={() => {
                      modalRight.current.scrollTop -= 10000;
                    }}
                  >
                    3. Impressum
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="modalLeftItem"
                    action
                    href="#datenschutz"
                    onClick={() => {
                      modalRight.current.scrollTop -= 10000;
                    }}
                  >
                    4. Datenschutz
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={8}>
                <Tab.Content className="modalRight" ref={modalRight}>
                  <Tab.Pane className="modalRightTab" eventKey="#news">
                    <h6>News</h6>
                    <News />
                  </Tab.Pane>
                  <Tab.Pane className="modalRightTab" eventKey="#kontakt">
                    <h6>Kontaktdaten</h6>
                    <p>
                      Grundlegende Fragen: <br></br>
                      Email: <span>kontakt@sagos.info</span> <br></br>
                      <br></br>
                      <span>Der Vorstand</span>
                      <br></br>
                      <br></br>
                      <span>Präsident:</span> <br></br>
                      Dr. med. Oliver Bartholomä
                      <br></br> Kirchmattweg 3a, 6340 Baar
                      <br></br> Tel 041 712 18 18
                      <br></br>
                      E-Mail: doktor@praxis-bartholomae.ch <br></br> <br></br>
                      <span>Vize-Präsident:</span> <br></br>
                      Dr. med. Michael Hertsch <br></br>
                      Facharzt für Urologie FMH <br></br> Hauptstrasse 129, 4102
                      Binningen
                      <br></br> Tel 061 422 02 69<br></br> FAX 061 422 02 71
                      <br></br>
                      E-Mail: mchertsch61@gmail.com <br></br> <br></br>
                      <span>Kassier:</span> <br></br>
                      Dr. med. Adrian Bühler <br></br> Facharzt FMH für Allg.
                      Medizin <br></br> Spiezbergstrasse 18, 3700 Spiez
                      <br></br>
                      Tel 079 390 89 56 <br></br>
                      E-Mail: buehler.medico@bluewin.ch <br></br>
                      <br></br>
                      <span> Aktuar:</span> <br></br> Dr. med. Rodolfo Roth
                      <br></br>
                      Facharzt FMH für Allg. Medizin<br></br> Seestrasse 561,
                      8038 Zürich<br></br> E-Mail: rodolfo.roth@hin.ch <br></br>
                      <br></br>
                      {/* <span>Beisitzer:</span> <br></br> Dr. med. Thomas C.
                      Carmine <br></br>
                      Allgemeine Innere Medizin FMH, <br></br> Neuraltherapie
                      SANTH Akupunktur ASA <br></br>Etzelstrasse 21, 8808
                      Pfäffikon SZ<br></br> Tel 055 415 50 30<br></br> FAX 055
                      415 50 31<br></br>E-Mail: info@dr-carmine.ch */}
                    </p>
                  </Tab.Pane>
                  <Tab.Pane className="modalRightTab" eventKey="#impressum">
                    <h6>Impressum</h6>
                    <Impressum></Impressum>
                  </Tab.Pane>
                  <Tab.Pane className="modalRightTab" eventKey="#datenschutz">
                    <h6>Datenschutz</h6>
                    <Datenschutz></Datenschutz>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
      <Navbar
        fixed="top"
        className="navbar"
        collapseOnSelect
        expand="lg"
        ref={(el) => (navbarComponent = el)}
      >
        <Navbar.Brand href="#ozontherapie">
          <img
            src="/images/sagosLogo2.png"
            width="250"
            height="50"
            className="d-inline-block align-top"
            alt="Sagos Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto navItems">
            <Nav.Link
              className="inactiveLink itemsSpace"
              onClick={() => {
                handleShow();
                window.fullpage_api.setAutoScrolling(false);
              }}
            >
              Generelle Infos
            </Nav.Link>
            <Link
              className={
                section === "ozon"
                  ? "activeLink itemsSpace"
                  : "inactiveLink itemsSpace"
              }
              to="/"
            >
              Die Ozontherapie
            </Link>
            <Link
              to="/sagos"
              className={
                section === "sagos"
                  ? "activeLink itemsSpace"
                  : "inactiveLink itemsSpace"
              }
            >
              Informationen zu SAGOS
            </Link>
            {memberAuth ? (
              <Link
                to="/membersarea"
                className={
                  section === "membersarea"
                    ? "activeLink itemsSpace"
                    : "inactiveLink itemsSpace"
                }
              >
                Mitglieder
              </Link>
            ) : (
              <Link
                to="/mitglieder"
                className={
                  section === "mitglieder"
                    ? "activeLink itemsSpace"
                    : "inactiveLink itemsSpace"
                }
              >
                Mitglieder
              </Link>
            )}
            <Link
              className={
                section === "admin"
                  ? "activeLink itemsSpace"
                  : "inactiveLink itemsSpace"
              }
              to="/admin"
            >
              Admin
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
