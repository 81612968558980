import React, { useEffect, useRef } from "react";
import "../sagos/sagosContent1Style.scss";
import "../modalStyle.scss";
import { TimelineLite, Power3, TweenMax } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { userLanguage } from "../../actions/actions";

export default function SagosContent1({ fullpage, animation }) {
  let tl = new TimelineLite({ delay: 0.3 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();
  let image3 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let rowSection = useRef();

  useEffect(() => {
    TweenMax.to(rowSection, 0, { css: { visibility: "visible" }, delay: 0.3 });
  }, []);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(image3, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.4)
        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === "deutsch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("deutsch"));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === "französisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("französisch"));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === "italienisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("italienisch"));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Vorstand und Mitglieder von SAGOS
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + "images/background/arrowDown.svg"}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div
        className="row sagosRow1 justify-content-center align-self-center"
        ref={(el) => (rowSection = el)}
      >
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>

        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Die Schweizerische Ärztegesellschaft<span> SAGOS</span>
          </h1>
          <p>
            Schweizerische Ärztegesellschaft für Ozon- und Sauerstoffanwendung
            in Therapie und Prophylaxe - gegründet 1986. Der Verein zählt
            aktuell an die hundert aktive Mitglieder!
          </p>
          <div className="buttonCenter">
            <button onClick={() => fullpage.moveSectionDown()}>
              Mehr zu SAGOS
            </button>
          </div>
        </div>
        <div className="col-md-8 leftSide">
          {" "}
          <img
            className="sagosImage1-1 imageHide"
            ref={(el) => {
              image1 = el;
            }}
            src={process.env.PUBLIC_URL + "images/background/ozon3.1.svg"}
            alt="doctors"
          ></img>
          <img
            className="sagosImage1-2 imageHide"
            ref={(el) => {
              image2 = el;
            }}
            src={process.env.PUBLIC_URL + "images/background/ozon3.2.svg"}
            alt="doctors"
          ></img>
          <img
            className="sagosImage1-3 imageHide"
            ref={(el) => {
              image3 = el;
            }}
            src={process.env.PUBLIC_URL + "images/background/ozon3.3.svg"}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
