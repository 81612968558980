import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Section1Wrapper from '../src/components/ozone/section1Wrapper';
import Section2Wrapper from '../src/components/sagos/section2Wrapper';
import Section3Wrapper from '../src/components/members/section3Wrapper';
import Section3Members from '../src/components/members/section3members';
import AdminPanel from './components/members/section3Admin';
import './components/mediaQueries.scss';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers/reducers';

function App() {
  const store = createStore(reducers);
  return (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route
              path="/membersarea"
              component={Section3Members}
              exact
            ></Route>
            <Route path="/admin" component={AdminPanel} exact></Route>
            <Route path="/mitglieder" component={Section3Wrapper} exact></Route>
            <Route path="/sagos" component={Section2Wrapper} exact></Route>
            <Route path="/" component={Section1Wrapper} exact></Route>
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
