import React, { useState, useEffect, useRef } from "react";
import "../sagos/sagosContent2Style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPhone,
  faGlobe,
  faArrowCircleLeft,
  faArrowCircleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { TimelineLite, Power3 } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { userLanguage } from "../../actions/actions";
import firebase from "firebase";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default function SagosContent2({ animation, fullpage }) {
  const [activeRegion, setActiveRegion] = useState("A-Z");
  const regionWrapper = useRef();
  const cardWrapper = useRef();
  const [members, setMembers] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchQuery, setSearchInput] = useState("");
  const membersPdf = new jsPDF();

  let cardSize = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  let tl = new TimelineLite({ delay: 1 });

  let rowRef = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyAu8r9P_43YLkv5aNAP85Cg6I42Ew5MNHI",
      authDomain: "sagosweb-e50b5.firebaseapp.com",
      databaseURL: "https://sagosweb-e50b5.firebaseio.com",
      projectId: "sagosweb-e50b5",
      storageBucket: "sagosweb-e50b5.appspot.com",
      messagingSenderId: "713864211879",
      appId: "1:713864211879:web:55f2c7b6e585dbdb7fda82",
    });
  } else {
    firebase.app();
  }
  let db = firebase.firestore();

  const checkMonth = () => {
    const date = new Date();
    const month = date.getMonth();
    switch (month) {
      case 0: {
        return "Januar";
      }
      case 1: {
        return "Februar";
      }
      case 2: {
        return "März";
      }
      case 3: {
        return "Apil";
      }
      case 4: {
        return "Mai";
      }
      case 5: {
        return "Juni";
      }
      case 6: {
        return "Juli";
      }
      case 7: {
        return "August";
      }
      case 8: {
        return "September";
      }
      case 9: {
        return "Oktober";
      }
      case 10: {
        return "November";
      }
      case 11: {
        return "Dezember";
      }
      default:
        break;
    }
  };

  const checkYear = () => {
    return new Date().getFullYear();
  };

  const generateTable = () => {
    return members.map((el, i) => {
      return [
        i + 1,
        `${el.degree} ${el.firstName} ${el.lastName} (${el.status})`,
        `${el.city}, ${el.street} (${el.region})`,
        `${el.phone}`,
        `${
          el.website === "construction.iliaroger.de"
            ? "Keine Angabe"
            : el.website
        }`,
      ];
    });
  };

  const createPdf = () => {
    const list = [...generateTable()];
    membersPdf.text(
      `Sagos Ärzteliste - ${checkMonth()} ${checkYear()}`,
      70,
      15
    );
    membersPdf.autoTable({
      head: [["ID", "Name", "Anschrift", "Telefon", "Webseite"]],
      body: list,
      startY: 25,
    });
    membersPdf.save("SagosÄrzteliste.pdf");
  };

  useEffect(() => {
    if (searchQuery === "") {
      setActiveRegion("A-Z");
    } else setActiveRegion("Custom");
  }, [searchQuery]);

  useEffect(() => {
    db.collection("members")
      .get()
      .then((querySnapshot) => {
        const membersData = [];
        querySnapshot.forEach((el) => {
          membersData.push(el.data());
        });
        const sortedMembers = membersData.sort((a, b) => {
          if (b.lastName > a.lastName) {
            return -1;
          } else return null;
        });
        setMembers(sortedMembers);
        setDataLoaded(true);
      });
  }, [db]);

  useEffect(() => {
    if (animation) {
      tl.from(rowRef, 1.5, {
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.4
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.4
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div className="sagosSection2LangNav">
        <div className="languageSelector" ref={(el) => (languageSection = el)}>
          <h1
            className={
              currentLanguage === "deutsch"
                ? "activeLanguage"
                : "inactiveLanguage"
            }
            onClick={() => {
              dispatch(userLanguage("deutsch"));
            }}
          >
            DE
          </h1>
          <h1
            className={
              currentLanguage === "französisch"
                ? "activeLanguage"
                : "inactiveLanguage"
            }
            onClick={() => {
              dispatch(userLanguage("französisch"));
            }}
          >
            FR
          </h1>
          <h1
            className={
              currentLanguage === "italienisch"
                ? "activeLanguage"
                : "inactiveLanguage"
            }
            onClick={() => {
              dispatch(userLanguage("italienisch"));
            }}
          >
            IT
          </h1>
        </div>
        <div
          className="navigationText"
          ref={(el) => (scrollSection = el)}
          onClick={() => {
            fullpage.moveSectionDown();
          }}
        >
          <h1>
            Nächstes Thema: <br></br>Wie kann man SAGOS beitreten?
          </h1>
          <div className="imageWrapper">
            <img
              src={process.env.PUBLIC_URL + "images/background/arrowDown.svg"}
              alt="scroll down icon"
            ></img>
          </div>
        </div>
      </div>
      <div className=" container sagosCardWrapper" ref={(el) => (rowRef = el)}>
        <div className="row sagosRow2">
          <div className="col-md-12 rightSide">
            <h1>
              Der <span>Vorstand</span> und die <span>Mitglieder</span> von
              SAGOS
            </h1>
          </div>
          <div className="col-md-12 searchMember">
            <div className="searchBox">
              <p>Nach einem Mitglied suchen:</p>
              <input
                onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
              ></input>
            </div>
            <div className="pdfBox" onClick={() => createPdf()}>
              <FontAwesomeIcon
                className="pdfIcon"
                icon={faDownload}
              ></FontAwesomeIcon>
              <p>SagosÄrzteListe.pdf</p>
            </div>
          </div>
          <div className="col-md-12 regionColumn">
            <div
              className="scrollLeft"
              onClick={() => {
                regionWrapper.current.scrollLeft -= 200;
              }}
            >
              <FontAwesomeIcon
                className="scrollIconLeft"
                icon={faChevronLeft}
              ></FontAwesomeIcon>
            </div>
            <div
              className="scrollRight"
              onClick={() => {
                regionWrapper.current.scrollLeft += 200;
              }}
            >
              <FontAwesomeIcon
                className="scrollIconRight"
                icon={faChevronRight}
              ></FontAwesomeIcon>
            </div>
            <div className="regionWrapper" ref={regionWrapper}>
              <div className="regionPaddingLeft"></div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("A-Z");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "A-Z"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  A-Z
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Vorstand");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Vorstand"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Vorstand
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Bern");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Bern"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Raum Bern
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Basel");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Basel"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Raum Basel
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Olten/Aarau");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Olten/Aarau"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Olten/Aarau
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Zentralschweiz");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Zentralschweiz"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Zentralschweiz
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Tessin");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Tessin"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Tessin
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Rheintal");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Rheintal"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Rheintal/Graubünden
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Zürich");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Zürich"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Raum Zürich
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Bodensee");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Bodensee"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Raum Bodensee
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Wallis");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Wallis"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Wallis
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Westschweiz");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Westschweiz"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Westschweiz
                </p>
              </div>
              <div
                className="regionCard"
                onClick={() => {
                  setActiveRegion("Schaffhausen");
                  cardWrapper.current.scrollLeft -= 5000;
                }}
              >
                <p
                  className={
                    activeRegion === "Schaffhausen"
                      ? "activeRegionButton"
                      : "inactiveRegionButton"
                  }
                >
                  Schaffhausen
                </p>
              </div>
              <div className="regionPaddingRight"></div>
            </div>
          </div>
          <div className="col-md-12 cardSection">
            <div className="cardWrapper" ref={cardWrapper}>
              <div className="cardScrollLeft">
                <FontAwesomeIcon
                  className="cardLeftIcon"
                  icon={faArrowCircleLeft}
                  onClick={() => {
                    if (
                      cardSize.current === undefined ||
                      cardSize.current === null
                    )
                      return;
                    cardWrapper.current.scrollLeft -=
                      cardSize.current.getBoundingClientRect().width + 20;
                  }}
                ></FontAwesomeIcon>
              </div>
              <div className="cardScrollRight">
                <FontAwesomeIcon
                  className="cardRightIcon"
                  icon={faArrowCircleRight}
                  onClick={() => {
                    if (
                      cardSize.current === undefined ||
                      cardSize.current === null
                    )
                      return;
                    cardWrapper.current.scrollLeft +=
                      cardSize.current.getBoundingClientRect().width + 20;
                  }}
                ></FontAwesomeIcon>
              </div>
              {dataLoaded &&
                members.map((elem, i) => {
                  if (elem !== null) {
                    switch (activeRegion) {
                      case "Custom": {
                        if (
                          elem.lastName &&
                          elem.lastName.toLowerCase().search(searchQuery) !== -1
                        ) {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "A-Z": {
                        return (
                          <div
                            className="col-md-3 memberCard"
                            key={i}
                            ref={cardSize}
                          >
                            <div className="memberCardImage">
                              <img
                                src={process.env.PUBLIC_URL + `${elem.image}`}
                                alt={`${elem.firstName} ${elem.lastName}`}
                              ></img>
                            </div>
                            <div className="memberCardDescription">
                              <h4>{elem.degree}</h4>
                              <h3>
                                <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                              </h3>
                              <p>{`${elem.street}`}</p>
                              <p className="city">{`${elem.city}`}</p>
                            </div>
                            <div className="memberCardStatus">
                              <p>{`${elem.status}`}</p>
                            </div>
                            <div className="row memberCardButtons">
                              <div className="col-md-6 memberCardLeftButton">
                                <FontAwesomeIcon
                                  className="memberCardIconLeft"
                                  icon={faGlobe}
                                ></FontAwesomeIcon>
                                <a
                                  href={`https://${elem.website}`}
                                  style={{
                                    pointerEvents: `${
                                      elem.website === "Keine Angabe"
                                        ? "none"
                                        : "auto"
                                    }`,
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Zur Webseite
                                </a>
                              </div>
                              <div className="col-md-6 memberCardRightButton">
                                <FontAwesomeIcon
                                  className="memberCardIconRight"
                                  icon={faPhone}
                                ></FontAwesomeIcon>
                                <p>{`${elem.phone}`}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      case "Vorstand": {
                        if (elem.board === true) {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }

                      case "Bern": {
                        if (elem.region === "Bern") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Basel": {
                        if (elem.region === "Basel") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Olten/Aarau": {
                        if (elem.region === "Olten/Aarau") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Zentralschweiz": {
                        if (elem.region === "Zentralschweiz") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Tessin": {
                        if (elem.region === "Tessin") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Rheintal": {
                        if (elem.region === "Rheintal") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Zürich": {
                        if (elem.region === "Zürich") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Bodensee": {
                        if (elem.region === "Bodensee") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Wallis": {
                        if (elem.region === "Wallis") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Westschweiz": {
                        if (elem.region === "Westschweiz") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      case "Schaffhausen": {
                        if (elem.region === "Schaffhausen") {
                          return (
                            <div
                              className="col-md-3 memberCard"
                              key={i}
                              ref={cardSize}
                            >
                              <div className="memberCardImage">
                                <img
                                  src={process.env.PUBLIC_URL + `${elem.image}`}
                                  alt={`${elem.firstName} ${elem.lastName}`}
                                ></img>
                              </div>
                              <div className="memberCardDescription">
                                <h4>{elem.degree}</h4>
                                <h3>
                                  <span>{` ${elem.firstName} ${elem.lastName}`}</span>
                                </h3>
                                <p>{`${elem.street}`}</p>
                                <p className="city">{`${elem.city}`}</p>
                              </div>
                              <div className="memberCardStatus">
                                <p>{`${elem.status}`}</p>
                              </div>
                              <div className="row memberCardButtons">
                                <div className="col-md-6 memberCardLeftButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconLeft"
                                    icon={faGlobe}
                                  ></FontAwesomeIcon>
                                  <a
                                    href={`https://${elem.website}`}
                                    style={{
                                      pointerEvents: `${
                                        elem.website === "Keine Angabe"
                                          ? "none"
                                          : "auto"
                                      }`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Zur Webseite
                                  </a>
                                </div>
                                <div className="col-md-6 memberCardRightButton">
                                  <FontAwesomeIcon
                                    className="memberCardIconRight"
                                    icon={faPhone}
                                  ></FontAwesomeIcon>
                                  <p>{`${elem.phone}`}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
