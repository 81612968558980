import React, { useState, useEffect, useRef } from 'react';
import '../members/membersContent3Style.scss';
import { Modal, Row, Col, ListGroup, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TimelineLite, Power3, TweenMax } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { userLanguage } from '../../actions/actions';
import {News} from "../News";

export default function MembersContent3({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let tl = new TimelineLite({ delay: 0.3 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let rowSection = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    TweenMax.to(rowSection, 0, { css: { visibility: 'visible' }, delay: 0.3 });
  }, []);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, {
        x: 50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          onExited={() => {
            fullpage.reBuild();
          }}
          show={show}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Wichtige Termine</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#infos"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#infos"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Infos
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#termine"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Termine
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane className="modalRightTab" eventKey="#infos">
                      <h6>Grundlegende Informationen</h6>
                      <div>
                        <p>
                          <span>1. </span>
                          Eine regelmässige Teilnahme an unseren Seminaren wird
                          sehr empfohlen und ist auch Voraussetzung für die
                          Zertifikatsverlängerung. Unsere Seminare finden in der
                          Regel zwei mal jährlich statt und sind auch für
                          Neuinteressenten / Einsteiger geeignet.
                          <br></br>
                          <br></br>
                          <span>2. </span>Termingerechte Anmeldungen für die
                          Seminare sind für uns sehr wichtig und höflichst
                          erbeten an die auf dem Programm angegebene Adresse.{' '}
                          <br></br>
                          <br></br>
                          Bei ungenügender Teilnehmerzahl oder
                          ausserordentlichen Umständen ist kurzfristige
                          Annullierung der Seminare möglich. Eine allfällige
                          Annullation des Seminars würde spätestens 24 Std vor
                          Seminarbeginn an dieser Stelle kommuniziert werden!
                          <br></br>
                          <br></br>
                          <span>3. </span> Die{' '}
                          <span>Zertifizierung der Mitglieder </span>
                          erfolgt an unseren Seminaren (ausschliesslich nach
                          Voranmeldung möglich)! <br></br>
                          Voraussetzung zur Zertifizierung sind nebst der
                          Mitgliedschaft mehrere absolvierte Teilnahmen an
                          unseren Seminaren sowie mindestens ein Jahr eigene
                          praktische Erfahrung mit Ozontherapie. Die
                          Zertifizierung erfolgt vor einem Prüfungsausschuss
                          mittels praktischer und theoretischer Prüfung.{' '}
                          <br></br>
                          <br></br> Für die Gültigkeitsverlängerung
                          (Rezertifizierung) sind jeweils mindestens 3 weitere
                          Seminarbesuche innert 5 Jahren erforderlich. übrigens
                          ... : Eigene fachliche Beiträge von Mitgliedern an
                          unseren Seminarien sind immer willkommen.
                          Interessenten melden sich bitte beim Präsidenten.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#termine">
                      <h6>Alle Termine</h6>
                      <div>
                        <News/>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === 'deutsch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('deutsch'));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === 'französisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('französisch'));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === 'italienisch'
              ? 'activeLanguage'
              : 'inactiveLanguage'
          }
          onClick={() => {
            dispatch(userLanguage('italienisch'));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Informationen zur Krankenversicherung
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + 'images/background/arrowDown.svg'}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div
        className="row justify-content-center align-self-center membersRow3"
        ref={(el) => (rowSection = el)}
      >
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Kursangebote, Seminare, Treffen <br></br>
            und <span>Kongresse</span>
          </h1>
          <p>
            Eine regelmässige Teilnahme an unseren Seminaren wird sehr empfohlen
            und ist auch Voraussetzung für die Zertifikatsverlängerung. Unsere
            Seminare finden in der Regel zwei mal jährlich statt und sind auch
            für Neuinteressenten / Einsteiger geeignet.
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
        <div className="col-md-8">
          <img
            className="membersImage1-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + 'images/background/members1.1.svg'}
            alt="doctors"
          ></img>
          <img
            className="membersImage1-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + 'images/background/members1.2.svg'}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
